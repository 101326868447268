//https://gist.github.com/McKabue/24efe7621642f6e61fe86c626c7e0271
//https://developer.mozilla.org/en/docs/Web/API/GlobalEventHandlers/onerror
//https://blog.sentry.io/2016/01/04/client-javascript-reporting-window-onerror.html
//https://danlimerick.wordpress.com/2014/01/18/how-to-catch-javascript-errors-with-window-onerror-even-on-chrome-and-firefox/

import { DateTimeFormatter, LocalDateTime, ZoneOffset } from '@js-joda/core';

import { uuidv4Math } from '~/utils/uuidv4Math';

window.addEventListener('error', function (e) {
  const { message, filename, lineno, colno, error } = e;
  const { name, message: errorMessage, stack } = (error || {}) as Error;
  const id = uuidv4Math();
  const timestamp = LocalDateTime.now(ZoneOffset.UTC).format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);
  const instanceId = window.instanceId ?? '';
  const clientId = localStorage.getItem('client-id') ?? '';
  const fingerprint = localStorage.getItem('fingerprint') ?? '';
  const tenantId = window.tenantId ?? '';
  const userId = window.userId ?? '';

  const envelope = {
    type: 'error',
    url: window.location.href,
    instanceId,
    clientId,
    fingerprint,
    tenantId,
    userId,
    body: {
      id,
      timestamp,
      message,
      filename,
      lineno,
      colno,
      name,
      errorMessage,
      stack,
    },
  };
  const blob = new Blob([JSON.stringify(envelope)], {
    type: 'application/reports+json',
  });
  const URL = '/network/reports/error-endpoint';
  (navigator.sendBeacon && navigator.sendBeacon(URL, blob)) ||
    fetch(URL, { body: blob, method: 'POST', keepalive: true });
  console.log('error', envelope);
});

// https://jsnlog.com/Documentation/HowTo/JavascriptErrorHandling?version=nodejs
// https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
addEventListener('unhandledrejection', event => {
  const { promise, reason } = event;

  const id = uuidv4Math();
  const timestamp = LocalDateTime.now(ZoneOffset.UTC).format(DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss.SSSX"));
  const instanceId = window.instanceId ?? '';
  const clientId = localStorage.getItem('client-id') ?? '';
  const fingerprint = localStorage.getItem('fingerprint') ?? '';
  const tenantId = window.tenantId ?? '';
  const userId = window.userId ?? '';

  const envelope = {
    type: 'rejection',
    url: window.location.href,
    instanceId,
    clientId,
    fingerprint,
    tenantId,
    userId,
    body: {
      id,
      timestamp,
      promise: JSON.stringify(promise),
      reason: JSON.stringify(reason),
    },
  };
  const blob = new Blob([JSON.stringify(envelope)], {
    type: 'application/reports+json',
  });
  const URL = '/network/reports/rejection-endpoint';

  (navigator.sendBeacon && navigator.sendBeacon(URL, blob)) ||
    fetch(URL, { body: blob, method: 'POST', keepalive: true });

  console.log('error', envelope);
});

// // Store a reference to the original console.error function
// const originalConsoleError = console.error;
//
// // Override console.error function
// console.error = function () {
//   // @ts-ignore
//   originalConsoleError.apply(console, arguments);
//   document.dispatchEvent(new CustomEvent("raisedError", { detail: {} }));
// };
